<template>
  <!-- <v-row
    justify="center"
  >
    <v-col sm="6">
      <form @submit.prevent="submit">
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-mail"
          autocapitalize="off"
          required
          @keyup="lowercase"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        />
        <v-text-field
          v-model="password"
          :error-messages="passwordErrors"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          label="Heslo"
          required
          @click:append="show = !show"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        />
        <v-btn
          color="primary"
          class="mr-4"
          @click="clear"
        >
          {{ $t('login.delete') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
        >
          {{ $t('login.log') }}
        </v-btn>
        <custom-input
          v-model="email"
          :placeholder="'email'"
          :error="emailErrors.length > 0"
          required
          @keyup="lowercase"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        >
          asdf
        </custom-input>
      </form>
    </v-col>
  </v-row> -->
  <div class="login-form">
    <form @submit.prevent="submit">
      <h3>
        {{ $t('login.welcome') }}<br>
        {{ $t('login.welcome2') }}
      </h3>
      <custom-input
        v-model="email"
        :placeholder="$t('login.emailPlaceholder')"
        :error="emailErrors.length > 0"
        required
        autofocus
        custom-input-type="email"
        class="email-input"
        @keyup="lowercase"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      >
        {{ $t('login.emailLabel') }}
        <template v-slot:errorMessage>
          {{ emailErrors[0] }}
        </template>
      </custom-input>
      <custom-input
        v-model="password"
        :placeholder="$t('login.passwordPlaceholder')"
        :error="passwordErrors.length > 0"
        required
        :custom-input-type="show ? 'text' : 'password'"
        @input="$v.password.$touch()"
        @blur="$v.password.$touch()"
      >
        {{ $t('login.passwordLabel') }}
        <template v-slot:errorMessage>
          {{ passwordErrors[0] }}
        </template>
        <template v-slot:rightIcon>
          <v-icon
            v-if="show"
            @click="show = !show"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-else
            @click="show = !show"
          >
            mdi-eye-off
          </v-icon>
        </template>
      </custom-input>
      <div
        v-if="isError.status === 403"
        v-click-outside="clearGlobalErrors"
        class="global-error"
      >
        {{ $t('login.error.globalError') }}
      </div>
      <custom-button
        class="login-button"
        @click="submit"
      >
        {{ $t('login.loginButton') }}
      </custom-button>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import CustomInput from '@/components/GlobalComponents/CustomInput.vue'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'

export default {
  name: 'LoginForm',
  components: {
    CustomInput,
    CustomButton
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required }
  },
  props: {
    isError: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  data: () => ({
    email: '',
    password: '',
    show: false
  }),
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$t('login.error.email.format'))
      !this.$v.email.required && errors.push(this.$t('login.error.email.required'))
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t('login.error.password.required'))
      return errors
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const data = {
        email: this.email,
        password: this.password
      }
      this.$emit('submit', data)
    },
    clearGlobalErrors () {
      this.isError.status = ''
    },
    // clear () {
    //   this.$v.$reset()
    //   this.email = ''
    //   this.password = ''
    // },
    lowercase () {
      this.email = this.email.toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
div.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 445px;

    h3 {
      color: $primary-white;
      margin-bottom: 72px;
    }

    .email-input {
      margin-bottom: 24px;
    }

    .login-button {
      margin-top: 48px;
      max-width: 120px;
    }

    div.global-error {
      color: $status-red;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: $mobile) {
  div.login-form {
    justify-content: flex-start;
    padding: 24px;

    form {
      align-items: flex-start;

      h3 {
        display: none;
      }

      .login-button {
        margin-top: 32px;
      }
    }
  }
}
</style>
